import { chosePaymentType } from '@api/expense';
import { isIOS } from '@utils/utils';
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      radio: 1,
      urlMerOrderId: '',
      urlAmount: ''
    };
  },
  computed: {
    amount() {
      return this.$route.query.erjiaTotalAmount;
    },
    phoneType() {
      return isIOS();
    }
  },
  watch: {},
  created() {
    this.getparams();
  },
  mounted() {},
  methods: {
    getparams() {
      let url = window.location.href;
      let obj = {},
        index = url.indexOf('?'),
        // 看url有没有参数
        params = url.substr(index + 1); // 截取url参数部分 name = aaa & age = 20

      if (index !== -1) {
        // 有参数时
        let parr = params.split('&'); // 将参数分割成数组 ["name = aaa", "age = 20"]
        for (let i of parr) {
          // 遍历数组
          let arr = i.split('='); // 1） i name = aaa   arr = [name, aaa]  2）i age = 20  arr = [age, 20]
          obj[arr[0]] = arr[1]; // obj[arr[0]] = name, obj.name = aaa   obj[arr[0]] = age, obj.age = 20
        }
      }

      this.urlMerOrderId = obj.erjiaOderId || '';
      this.urlAmount = obj.erjiaTotalAmount || '';
    },
    handleChange(e) {
      console.log(e);
    },
    handleNext() {
      chosePaymentType({
        order: this.$route.query.erjiaOderId || this.urlMerOrderId,
        type: this.radio,
        systemType: this.phoneType === 'android' ? 'ANDROID' : 'IOS'
      }).then(res => {
        let result = res.data;
        if (result.code === '10000') {
          window.location.href = result.data.paymentUrl;
        }
      });
    }
  }
};